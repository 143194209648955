import React, { useState, useEffect } from "react";
import { navigate } from "gatsby";
import { Form, Divider, message } from "antd";
import { MdOutlineMail, MdPassword } from "react-icons/md";
import { Button, Input, Text } from "@geist-ui/core";
import HomeLayout from "../blocks/layout/home";
import Seo from "../blocks/layout/seo";

import { Firebase } from "../config/firebase";
import "../styles/auth-page.scss";
import { useLocation } from "@reach/router";

const SignupPage = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const [googleLoading, setGoogleLoading] = useState(false);
  const [, forceUpdate] = useState({});

  // get query mode param from url
  const mode = new URLSearchParams(location.search).get("mode");
  console.log(" -> ", mode);

  useEffect(() => {
    forceUpdate({});
    Firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        navigateToDashboard();
      }
    });
  }, []);

  const navigateToDashboard = () => {
    if (mode === "creator") {
      navigate("/dashboard?mode=creator");
    } else {
      navigate("/dashboard");
    }
  };

  const signupWithEmail = (values) => {
    const { email, password } = values;
    setLoading(true);
    Firebase.auth()
      .createUserWithEmailAndPassword(email, password)
      .then((result) => {
        // navigate('/dashboard')
        form.resetFields();
        message.success("You are registered successfully!");
        navigateToDashboard();
      })
      .catch((err) => {
        if (err.code === "auth/email-already-in-use") {
          message.info(
            "The email address is already in use by another account"
          );
        } else {
          message.error(err.message);
        }
        setLoading(false);
        form.resetFields();
      });
  };

  const loginWithGoogle = (e) => {
    e.preventDefault();
    setGoogleLoading(true);
    const providerOAuth = new Firebase.auth.GoogleAuthProvider();

    Firebase.auth()
      .signInWithPopup(providerOAuth)
      .then((result) => {
        setGoogleLoading(false);
      })
      .catch((err) => {
        setGoogleLoading(false);
        if (err.code === "auth/account-exists-with-different-credential") {
          message.info("Try to use email-password login");
        } else {
          message.error(err.message);
        }
      });
  };
  return (
    <div className="auth-page">
      <Seo title="Create an account — Output" />
      <HomeLayout authPage>
        <div className="auth-form">
          <div className="heading">
            <h2>Create an account</h2>
            <Button
              width="350px"
              style={{
                padding: "0px 30px",
                width: 300,
                fontSize: 15,
                marginTop: 40,
              }}
              loading={googleLoading}
              onClick={loginWithGoogle}
            >
              <img
                src="/google-logo.png"
                alt="google login"
                height="28px"
                width="28px"
                style={{
                  marginRight: 15,
                }}
              />
              <span>Sign up with Google</span>
            </Button>
            <Divider
              plain
              style={{
                margin: "40px 0px",
                fontWeight: 300,
                fontSize: 13,
                color: "#606060",
              }}
            >
              or sign up with email
            </Divider>

            <Form
              form={form}
              name="signup_form"
              className="auth-input-form"
              initialValues={{ remember: true }}
              onFinish={signupWithEmail}
            >
              <Form.Item name="email">
                <Input
                  placeholder="Email Address"
                  required
                  width="350px"
                  icon={<MdOutlineMail />}
                />
              </Form.Item>
              <Form.Item name="password">
                <Input.Password
                  placeholder="Password"
                  required
                  width="350px"
                  icon={<MdPassword />}
                />
              </Form.Item>
              <Form.Item shouldUpdate>
                {() => (
                  <Button
                    type="success"
                    htmlType="submit"
                    loading={loading}
                    width="350px"
                    className="auth-btn auth-form-button"
                  >
                    Create Free Account
                  </Button>
                )}
              </Form.Item>
            </Form>
          </div>
        </div>
      </HomeLayout>
    </div>
  );
};

export default SignupPage;
